import { EventEmitter, Injectable } from '@angular/core';

export enum DexterEventType {
  SELECT_ITEM = 'SELECT_ITEM',
  FILTER_CHANGED = 'FILTER_CHANGED',
}

export interface GlobalEvent {
  eventName: DexterEventType;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalEventsService {
  public onEvent: EventEmitter<GlobalEvent> = new EventEmitter<GlobalEvent>();
  private _emitters: { [eventName: string]: EventEmitter<any> } = {};

  get(eventName: DexterEventType): EventEmitter<GlobalEvent> {
    if (!this._emitters[eventName]) {
      this._emitters[eventName] = new EventEmitter<any>();
      this._emitters[eventName].subscribe((data) =>
        this.onEvent.emit({
          eventName,
          data,
        }),
      );
    }
    return this._emitters[eventName];
  }

  emit(eventName: DexterEventType, data?: any): void {
    if (this._emitters[eventName]) {
      this._emitters[eventName].emit(data);
      this.onEvent.next({
        eventName,
        data,
      });
    }
  }
}
