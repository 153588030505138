import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { AdminState, AdminStateModel } from './admin.state';

@Injectable({ providedIn: 'root' })
export class AdminSelectors {
  @Selector([AdminState])
  static organizations(state: AdminStateModel) {
    return state.organizations;
  }

  @Selector([AdminState])
  static loadingOrganizations(state: AdminStateModel) {
    return state.loadingOrganizations;
  }

  @Selector([AdminState])
  static loadingSelectedOrganization(state: AdminStateModel) {
    return state.loadingSelectedOrganization;
  }

  @Selector([AdminState])
  static selectedOrganization(state: AdminStateModel) {
    return state.selectedOrganization;
  }

  @Selector([AdminState])
  static loadingWorkspace(state: AdminStateModel) {
    return state.loadingWorkspaces;
  }

  @Selector([AdminState])
  static workspace(state: AdminStateModel) {
    return state.workspace;
  }

  @Selector([AdminState])
  static loadingUsers(state: AdminStateModel) {
    return state.loadingUsers;
  }

  @Selector([AdminState])
  static users(state: AdminStateModel) {
    return state.users;
  }

  @Selector([AdminState])
  static loadinggroups(state: AdminStateModel) {
    return state.loadingGroups;
  }

  @Selector([AdminState])
  static groups(state: AdminStateModel) {
    return state.groups;
  }

  @Selector([AdminState])
  static folders(state: AdminStateModel) {
    return state.folders;
  }

  @Selector([AdminState])
  static tabs(state: AdminStateModel) {
    return state.tabs;
  }

  @Selector([AdminState])
  static selectedIndex(state: AdminStateModel) {
    return state.selectedIndex;
  }

  @Selector([AdminState])
  static newUser(state: AdminStateModel) {
    return state.newUser;
  }
}
